<template>
  <div>
    <h3 class="success pa-2 white--text rounded">
      Section C: Spiritual Data
    </h3>

    <v-row class="mt-4">
      <v-col cols="12" sm="6" md="4">
        <v-radio-group
          dense
          v-model="dform.born_again"
          row
          required
          :rules="[v => !!v || 'Item is required']"
        >
          <span class="mr-2">Are you born again (*)?: </span>
          <v-radio label="Yes" value="Yes" color="red"></v-radio>
          <v-radio label="No" value="No" color="red"></v-radio>
        </v-radio-group>
      </v-col>
      <v-col cols="12" sm="6" md="8" v-if="dform.born_again == 'Yes'">
        <p>When and How? Explain briefly (*)</p>
        <v-textarea
          v-model="dform.salvation_experience"
          rows="1"
          auto-grow
          dense
          outlined
          row-height="15"
          :rules="[v => !!v || 'Item is required']"
          required
        >
        </v-textarea>
      </v-col>

      <v-col cols="12" sm="6" md="5">
        <v-radio-group
          dense
          row
          v-model="dform.in_discipleship"
          required
          :rules="[v => !!v || 'Item is required']"
        >
          <span class="mr-3"
            >Are you in any discipleship relationship(*)?:
          </span>
          <v-radio label="Yes" value="Yes" color="red"></v-radio>
          <v-radio label="No" value="No" color="red"></v-radio>
        </v-radio-group>
      </v-col>

      <v-col cols="12" sm="6" md="7" v-if="dform.in_discipleship == 'Yes'">
        <p>With Whom?</p>
        <v-text-field
          v-model="dform.discipler_name"
          outlined
          dense
          :rules="[v => !!v || 'Item is required']"
          required
        >
        </v-text-field>
        <p>Address</p>
        <v-textarea
          v-model="dform.discipler_address"
          rows="1"
          auto-grow
          dense
          outlined
          row-height="15"
          :rules="[v => !!v || 'Item is required']"
          required
        >
        </v-textarea>
        <p>Phone Number</p>
        <v-text-field
          v-model="dform.discipler_phoneno"
           type="number"
          maxlength="11"
          counter="11"
          outlined
          dense
          :rules="[v => !!v || 'Item is required']"
          required
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="7">
        <p>Church you are attending (*)</p>

        <v-text-field
          v-model="dform.church"
          dense
          outlined
          required
          :rules="[v => !!v || 'Item is required']"

        >
        </v-text-field>
      </v-col>

      <v-col cols="12" sm="6" md="5">
        <v-radio-group dense row v-model="dform.mission_burdened">
          <span class="mr-3">Do you have any burden for missions?: </span>
          <v-radio label="Yes" value="Yes" color="red"></v-radio>
          <v-radio label="No" value="No" color="red"></v-radio>
        </v-radio-group>
      </v-col>

      <v-col v-if="dform.mission_burdened == 'Yes'">
        <p>How did it come? Explain briefly</p>
        <v-textarea
          v-model="dform.mission_burden_details"
          rows="1"
          auto-grow
          dense
          outlined
          row-height="15"
        >
        </v-textarea>
      </v-col>
    </v-row>

     <v-row justify="center" class="mb-2 mx-1">
      <v-btn small class="px-2" color="primary" @click="$emit('prev', { step: 2 })">
        Previous
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn small class="px-2" color="success" :disabled ="!isValid" @click="saveChanges">
        Save and Continue
      </v-btn>
    </v-row>
  </div>
</template>

<script>
import { apiClient } from "@/services";
import { editUserMixin } from "@/mixins";

export default {
  name: "SpiriTual",
  mixins: [editUserMixin],

 props: {
    formId: [String, Number],
    alert: Function,
  },
  data() {
    return {
      dform: {
        born_again: "",
        salvation_experience: "",
        in_discipleship: "",
        discipler_name: "",
        discipler_address: "",
        discipler_phoneno: "",
        church: "",
        mission_burdened: "",
        mission_burden_details: ""
      }
    };
  },
  computed: {
  isValid (){
    const reqf= ['born_again','in_discipleship','church','mission_burdened',
]
    return reqf.every(v => !!this.dform[v])
    }
},

  methods: {
    saveChanges() {
    if(!this.formId){
        this.alert("Please start from the beginning and fill all the forms", "error");
        return;
      }
      this.dform.id = this.formId;
      this.dform.type = "spiritual";
      this.loading = true;
      // Do Validation here
      apiClient
        .put("/applicants.php", this.dform)
        .then((res) => {
          if (res.data.ok) {
            this.alert();
            this.loading = false;
            this.$emit("updated-spiritual", { step: 4 });
          } else {
            this.alert(res.data, "error");
            this.loading = false;
          }
        })
        .catch((err) => {
          this.alert(err.message, "error");
          this.loading = false;
        });
    },
  }
};
</script>
