export const snackMixin = {
  data() {
    return {
      snackbar: false,
      snackMsg: "",
      snackColor: "",
      loading: false,
    };
  },

  methods: {
    displayMsg(msg, color = "success", loading = "loading", state = true) {
      this.snackMsg = msg;
      this.snackColor = color;
      this.snackbar = state;
      this[loading] = false;
    }
  }
};

export const editUserMixin = {
  computed: {
    editUser() {
      return this.$route.params.editUser;
    },
  },

  created() {
    this.editUser && (this.dform = this.editUser);
  },
};