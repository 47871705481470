<template>
  <v-app>
   <NavBar :drawer = "drawer" :mobile = "mobile" class="d-print-none" />

    <v-main>
      <router-view></router-view>
    </v-main>

 <footer-page class="d-print-none"></footer-page>
  </v-app>
</template>

<script>
import FooterPage from './components/FooterPage.vue';
import NavBar from './components/NavBar.vue';

export default {
  name: "App",
  components: {
     NavBar,
     FooterPage
  },

  data() {
  return {
    drawer: false,
   }
 },
 
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.smAndDown
    },   
  },

watch: {
  mobile(newval){
    if(!newval){
      this.hideDrawer()
    }
  }
},
methods: {
 
  hideDrawer() {
      this.drawer = false
    }
  },  
};
</script>

