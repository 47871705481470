<template>
  <div>
    <h3 class="success pa-2 white--text rounded">
      Section E: References
      <v-card-subtitle class="white--text"
        >Your parents cannot be your referee. You cannot not be your own referee. If you refer yourself, that will be unrighteousness. Recommendation from referees must
        get to us two(2) weeks after submission, Without referee submission,
        your application will not be treated.</v-card-subtitle
      >
    </h3>

    <v-row>
      <v-col
        cols="12"
        sm="6"
        v-for="(ref, idx) in dform.referees"
        :key="`ref${idx}`"
      >
        <h3 class="success--text">REFEREE {{ idx + 1 }}:</h3>
        <h4 v-if="dform.submitted" class="success--text">
          <b>Reference Submitted By Referee: </b>
          <span>{{ ref.submitted ? "Yes  " : "No  " }}</span>
          <v-btn v-if="!ref.submitted" x-small :loading="idx==0?loadingRef1Email:loadingRef2Email" color="primary" @click="resendMail(dform, ref, `loadingRef${idx+1}Email`)" class="px-2"> Resend email</v-btn>
        </h4>
        <p class="mt-4">Name (*) Surname First</p>
        <v-text-field
          v-model="ref.referee_name"
          outlined
          dense
          required
           :rules="[v => !!v || 'Item is required']"
        ></v-text-field>

        <p>Contact Address</p>
        <v-text-field
          v-model="ref.referee_address"
          outlined
          dense
          required
           :rules="[v => !!v || 'Item is required']"
        ></v-text-field>

        <p>Phone Number</p>
        <v-text-field
          v-model="ref.referee_phoneno"
          type="number"
          maxlength="11"
          counter="11"
          outlined
          dense
          :rules="[v => !!v || 'Item is required']"
          required
        ></v-text-field>

        <p>Email</p>
        <v-text-field
          v-model="ref.referee_email"
          type="email"
          outlined
          dense
          required
           :rules="[v => !!v || 'Item is required']"
        ></v-text-field>
      </v-col>
    </v-row>

     <v-row justify="center" class="mb-2 mx-1">
      <v-btn small class="px-2" color="primary" @click="$emit('prev', { step: 4 })">
        Previous
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn small class="px-2" :disabled="!isValid" color="success" @click="saveChanges">
        Save and Continue
      </v-btn>
    </v-row>
  </div>
</template>

<script>
import { apiClient } from "@/services";
import { editUserMixin } from "@/mixins";

export default {
  name: "ReFerees",
  mixins: [editUserMixin],

  props: {
    formId: [String, Number],
    alert: Function,   
  },
  data() {
    return {
      dform: {
        referees: this.initializeReferees(),
      },
      loadingRef1Email:false,
      loadingRef2Email:false,
    };
  },
  computed: {
  isValid (){
    const reqf= ['referee_name','referee_address','referee_phoneno','referee_email',
]
    return reqf.every(v => !!this.dform.referees[0][v] && !!this.dform.referees[1][v] )
    }
},


  created() {
    this.editUser && !this.editUser.referees.length && (this.dform.referees = this.initializeReferees());
  },
  methods: {
    initializeReferees (){
      return  [
          { id: "", referee_name: "", referee_address: "", referee_phoneno: "", referee_email: "", application_year:"" },
          { id: "", referee_name: "", referee_address: "", referee_phoneno: "", referee_email: "", application_year:"" }
        ];
    },

    saveChanges() {
      if(!this.formId){
        this.alert("Please start from the beginning and fill all the forms", "error");
        return;
      }      
    // console.log(this.dform)
    // console.log(this.formId)
        
        (this.dform.referees[0].id || this.dform.referees[1].id) ? this.updateReferees() : this.createReferees()
        
      
      
    },

      createReferees() {
      this.dform.applicant_id = this.formId;
      this.loading = true;
      // Do Validation here
      apiClient
        .post("/referees.php", this.dform)
        .then((res) => {
          if (Array.isArray(res.data)) {
            this.dform.referees = res.data
            this.alert();
            this.loading = false;
            this.$emit("updated-referees", { step: 6 });
          } else {
            this.alert(res.data, "error");
            this.loading = false;
          }
        })
        .catch((err) => {
          this.alert(err.message, "error");
          this.loading = false;
        });
    },
      
      updateReferees() {
      this.dform.type = "referee";
      this.loading = true;
      // Do Validation here
      apiClient
        .put("/referees.php", this.dform)
        .then((res) => {
          if (res.data.ok) {
            this.alert();
            this.loading = false;
            this.$emit("updated-referees", { step: 6 });
          } else {
            this.alert(res.data, "error");
            this.loading = false;
          }
        })
        .catch((err) => {
          this.alert(err.message, "error");
          this.loading = false;
        });
    },

    resendMail(applicant, referee, loadingType) {
      this[loadingType] = true;
      let data = {applicant: {...applicant, referees:[]}, referee}
      apiClient
        .post("/mail_referee.php", data)
        .then((res) => {
          if (+res.data.mailsent) {
            this.alert("Email sent successfully")
            this[loadingType] = false;
          } else {
            this.alert(res.data, "error");
            this[loadingType] = false;
          }
        })
        .catch((err) =>{
          this.alert(err.message, "error")
          this[loadingType] = false;
    })

    },
    }

};
</script>
