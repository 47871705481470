<template>
    <div>
      <v-row class="pa-6"  @click="$emit('close-drawer')" justify="end">
    <v-btn icon><v-icon color="white" class="text-end">mdi-close</v-icon></v-btn>
    </v-row>
      <div class="d-flex flex-column text-center">
     <v-btn :to="{name:'Home'}" class="white--text my-3" small text>Home</v-btn> 
     <v-btn :to="{name:'About'}" class="white--text my-3" small text>About</v-btn>
    <v-btn class="white--text my-3" small text @click="closeDrawer" >CONTACT</v-btn>
    </div>
    </div>
</template>
<script>
export default {
  name:"NavDrawer",
  methods:{
    closeDrawer(){
     this.$vuetify.goTo('#footer');
     this.$emit('close-drawer');
    }
  }
}
</script>