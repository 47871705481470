<template>
  <div>
    <h3 class="success pa-1 white--text rounded">
      Section B: Previous Educational Qualification
    </h3>

    <v-row class="mt-4">
      <v-col cols="12" sm="7" md="8">
        <p class="text-center">
          Name of school(s) previously attended with date (*)
        </p>
        <v-textarea
          rows="5"
          v-model="dform.previous_education"
          placeholder="Name of previous school(s)"
          outlined
          :rules="[v => !!v || 'Item is required']"
          required
        >
        </v-textarea>
      </v-col>
      <v-col cols="12" sm="5" md="4">
        <p class="text-center">Highest Educational Qualification (*)</p>
        <v-select
          v-model="dform.qualification"
          :items="qualifications"
          outlined
          :rules="[v => !!v || 'Item is required']"
          required
          placeholder="select qualification..."
        >
        </v-select>
      <div v-if="showCourseStudy">
        <p class="text-center">Course of Study (*)</p>
        <v-text-field
          v-model="dform.discipline"
          placeholder="e.g Computer Science"
          outlined
          :rules="[v => !!v || 'Item is required']"
          required
        >
        </v-text-field>
      </div>
      </v-col>
    </v-row>
     <v-row justify="center" class="mb-2 mx-1">
      <v-btn small class="px-2" color="primary" @click="$emit('prev', { step: 1 })">
        Previous
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn small class="px-2" color="success" :disabled ="!isValid" @click="saveChanges">
        Save and Continue
      </v-btn>
    </v-row>
  </div>
</template>

<script>
import { apiClient } from "@/services";
import { mapState } from "vuex";
import { editUserMixin } from "@/mixins";
export default {
  name: "EduCation",

mixins: [editUserMixin],

   props: {
    alert: Function,
    formId: [String, Number],
  },

  data() {
    return {
      dform: {
        previous_education: "",
        qualification: "",
        discipline: ""
      }
    };
  },
   computed: {
    ...mapState(["qualifications"]),
     isValid (){
      return this.dform.previous_education &&
        this.dform.qualification
    },
    showCourseStudy(){
      return this.dform.qualification !="" && this.dform.qualification !="SSCE"
    }
  },
  methods: {
    saveChanges() {
    if(!this.formId){
        this.alert("Please start from the beginning and fill all the forms", "error");
        return;
      }
      this.dform.id = this.formId;
      this.dform.type = "education";
      this.loading = true;
      // Do Validation here
      apiClient
        .put("/applicants.php", this.dform)
        .then((res) => {
          if (res.data.ok) {
            this.alert();
            this.loading = false;
            this.$emit("updated-education", { step: 3 });
          } else {
            this.alert(res.data, "error");
            this.loading = false;
          }
        })
        .catch((err) => {
          this.alert(err.message, "error");
          this.loading = false;
        });
    },
  }
};
</script>
