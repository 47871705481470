<template>
    <v-card max-width="800" class="mx-auto my-5">
        <about-details :about="true" />
    </v-card>
</template>

<script>
import AboutDetails from "@/components/AboutDetails.vue";
    export default {
        name: "About",
        components: {
            AboutDetails
        },   
    }
</script>