import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/Home.vue";
import About from "@/views/About.vue";
// import Gallery from "@/views/Gallery.vue";
import Application from "@/views/Application.vue";
import Reference from "@/views/Reference.vue";

Vue.use(VueRouter);

const routes = [{
    path: "/home",
    alias: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/about",
    name: "About",
    component: About
  },
  {
    path: "/application", // added -e to close
    alias: "/pin", 
    name: "Application",
    props: (route) => ({ editUser: route.params.editUser }),
    component: Application
  },
  {
    path: "/app-login", // added -e to close
    name: "ApplicantLogin",
    component: () =>
      import ( /* webpackChunkName: "applicantLogin" */ "@/views/ApplicantLogin.vue")
  },
  {
    path: "/applicant-dashboard",
    name: "ApplicantDashboard",
    props: (route) => ({ editUser: route.params.editUser }),
    component: () =>
      import ( /* webpackChunkName: "applicantDashboard" */ "@/views/ApplicantDashboard.vue")
  },
  {
    path: "/acceptance",
    name: "Acceptance",
    props: (route) => ({ editUser: route.params.editUser }),
    component: () =>
      import ( /* webpackChunkName: "acceptance" */ "@/views/Acceptance.vue")
  },
  // {
  //   path: "/gallery",
  //   name: "Gallery",
  //   component: Gallery
  // },
  {
    path: "/rf/:rf_id",
    name: "Reference",
    component: Reference,
    props: true
  },

  {
    path: "/admin",
    name: "Admin",
    component: () =>
      import ( /* webpackChunkName: "adminLogin" */ "@/views/Admin/Login.vue")
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: () =>
      import ( /* webpackChunkName: "adminDashboard" */ "@/views/Admin/Dashboard.vue"),
    children: [{
      path: "applicants",
      alias:"applicant",
      name: "NewApplicants",
      component: () =>
        import (
          /* webpackChunkName: "newApplicants" */
          "@/views/Admin/NewApplicants.vue"
        )
    },
    {
      path: "references",
      alias:"reference",
      name: "ReferenceDetail",
      component: () =>
        import (
          /* webpackChunkName: "ReferenceDetails" */
          "@/components/Admin/ReferenceDetails.vue"
        )
    }]
  },
  {
    path: "/404",
    alias: "*",
    name: "NotFound",
    component: () =>
      import ( /* webpackChunkName: "notFound" */ "@/views/NotFound.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;