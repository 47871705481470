<template>
  <div>
    <v-carousel height="100%" cycle hide-delimiters :show-arrows="false">
      <v-carousel-item v-for="slide in slides" :key="`slide${slide}`">
        <v-img :src="`img/slides/slide${slide}.jpg`" contain>
          <template #placeholder>
              <v-skeleton-loader
                v-bind="attrs"
                type="image"
                width="100%"
                height="100%"
              ></v-skeleton-loader>
            </template>
        </v-img>
      </v-carousel-item>
    </v-carousel>
  </div>
</template>

<script>
import { apiClient } from "@/services";
export default {
  name: "SlideBanner",
  data() {
    return {
      slides: 45,
      attrs: {
        class: "mb-6",
        boilerplate: true,
        elevation: 2,
      },
    };
  },
  async created(){
      try {
        const res = await apiClient.get( "/applicants_setup.php?_u=1" );
        if (Number.isInteger(res.data.slide_images)) {
          this.slides = res.data.slide_images;
        } 
      } catch (err) {
        console.log("slide error");
      }
  },
};
</script>
