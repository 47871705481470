<template>
  <div>
    <h3 class="success pa-2 white--text rounded">
      Section D: Details of Parent(s) or Guardian(s)
    </h3>

    <v-row class="mt-4">
      <v-col cols="12" sm="6">
        <p>Name of Parent(s) or Guardian(s) (Surname First)</p>
        <v-text-field v-model="dform.parent_name" outlined dense required>
        </v-text-field>
      </v-col>

      <v-col cols="12" sm="6">
        <p>Contact Address of Parent/Guardian</p>
        <v-text-field v-model="dform.parent_address" dense outlined required>
        </v-text-field>
      </v-col>
      <v-col cols="12" sm="6">
        <p>Phone Number of Parent/Guardian</p>
        <v-text-field
          v-model="dform.parent_phoneno"
           type="number"
          maxlength="11"
          counter="11"
          outlined
          dense
          :rules="[v => !!v || 'Item is required']"
          required
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6">
        <p>Email of Parent/Guardian</p>
        <v-text-field
          v-model="dform.parent_email"
          type="email"
          dense
          outlined
          required
        >
        </v-text-field>
      </v-col>
    </v-row>

     <v-row justify="center" class="mb-2 mx-1">
      <v-btn small class="px-2" color="primary" @click="$emit('prev', { step: 3 })">
        Previous
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn small class="px-2" :disabled ="!isValid" color="success" @click="saveChanges">
        Save and Continue
      </v-btn>
    </v-row>
  </div>
</template>

<script>
import { apiClient } from "@/services";
import { editUserMixin } from "@/mixins";

export default {
  name: "PaRent",
  mixins: [editUserMixin],
 
   props: {
    formId: [String, Number],
    alert: Function,
  },
  data() {
    return {
      dform: {
        parent_name: "",
        parent_address: "",
        parent_phoneno: "",
        parent_email: ""
      }
    };
  },

  computed: {
  isValid (){
    const reqf= ['parent_name','parent_address','parent_phoneno','parent_email',
]
    return reqf.every(v => !!this.dform[v])
    }
},

  methods: {
    saveChanges() {
      if(!this.formId){
        this.alert("Please start from the beginning and fill all the forms", "error");
        return;
      }
      this.dform.id = this.formId;
      this.dform.type = "parent";
      this.loading = true;
      // Do Validation here
      apiClient
        .put("applicants.php", this.dform)
        .then((res) => {
          if (res.data.ok) {
            this.alert();
            this.loading = false;
            this.$emit("updated-parent", { step: 5 });
          } else {
            this.alert(res.data, "error");
            this.loading = false;
          }
        })
        .catch((err) => {
          this.alert(err.message, "error");
          this.loading = false;
        });
    },
  }
};
</script>
