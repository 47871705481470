<template>
    <div>
     <v-app-bar color="white">
     <router-link to="/"><img src="/img/ati-logo.png" class="main-logo rounded" /></router-link>
     
       <div class="pl-2 text-center brand-tag" >
          
    <p class="text-center success--text darken pt-2" >Peace House <br>
      Agricultural Training Institute <br> Isarun, Ondo State,
        Nigeria</p >
      </div>
      <div class="pl-2 text-center">
        <img src="/img/ls-logo.png" class="sub-logo" />
      </div>
   <v-spacer></v-spacer>
      <div class="pt-2 ml-auto" v-show="!mobile">
     <v-btn to="/" color="success" small text>Home</v-btn>
     <v-btn to="/about" color="success" small text>About</v-btn>
     <v-btn color="success" small text @click="$vuetify.goTo('#footer')">Contact</v-btn> 
      </div>
   <v-app-bar-nav-icon class="success-text" v-show="mobile" @click="drawer = !drawer"></v-app-bar-nav-icon>
   </v-app-bar>

   <v-navigation-drawer
      v-model="drawer"
       app
       temporary
      hide-overlay
      right
      color="success"
    >
    
    <NavDrawer @close-drawer="drawer = false" />
     </v-navigation-drawer>

    </div>
</template>

<script>
 import NavDrawer from '@/components/NavDrawer.vue';
    export default {
  name:"NavBar",

  components: {
    NavDrawer,
  },

 props: {
   mobile: Boolean,
 },

 data() {
   return {
    drawer: false,
   }
 },
  }  
    
</script>

<style>

.main-logo {
  max-width: 3.5rem;
}

.sub-logo {
  max-width: 5rem;
}

.brand-tag {
  font-size: 0.6rem;
}

/* @media screen and (min-width: 350px) {
  .brand-tag {
    font-size: 0.6rem;
  }
  .main-logo {
    max-width: 6rem;
  }
  .sub-logo {
    max-width: 4rem;
  }
} */

/* @media screen and (min-width: 600px) {
  .main-logo {
    max-width: 6rem;
  }
  .sub-logo {
    max-width: 4.5rem;
  }
  .brand-tag {
    font-size: 0.7rem;
  }
} */

/* @media screen and (min-width: 900px) {
  .main-logo {
    max-width: 9rem;
  }
  .sub-logo {
    max-width: 5rem;
  }
  .brand-tag {
    font-size: 0.8rem;
  }
} */

/* @media screen and (min-width: 1200px) {
  .main-logo {
    max-width: 10rem;
  }
  .sub-logo {
    max-width: 5.5rem;
  }
  .brand-tag {
    font-size: 0.9rem;
  } 
}  */
</style>