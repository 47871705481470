<template>
  <div>
    <div>
      <h4 class="success pa-2 white--text text-center rounded">
        Documents Upload: You can compress your images using
        <a href="http://compressor.io" target="_blank" class="white--text"><b>compressor.io</b></a>
      </h4>
    </div>
<v-container fluid>
    <v-row justify="center" no-gutters>
      <v-col cols="12" lg="10" xl="8" class="text-center">
        <b>Passport</b>
        <v-img
          :src="`/uploads/${application_year}/${dform.passport}`"
          height="150"
          width="150"
          contain
          class="mx-auto"
        >
          <template #placeholder>
            <v-skeleton-loader
              v-bind="attrs"
              type="card-avatar, image"
            ></v-skeleton-loader>
          </template>
        </v-img>
        
        <v-file-input
          v-if="!dform.passport || edit.passport"
          accept="image/*"
          label="Upload your passport (<250KB)"
          :rules="rules.img"
          @change="handlePassport"
        ></v-file-input>
        
        <div v-else class="mt-2">
          <v-btn
            small
            color="primary"
            @click="edit.passport = true"
            >Change Image</v-btn
          >
        </div>
        <div v-if="edit.passport" class="my-0">
          <v-btn
            small
            color="primary"
            @click="edit.passport = false"
            >Cancel Image Change</v-btn
          >
        </div>
      </v-col>      

      <v-col v-if="!dform.awaiting_result" cols="12" lg="10" xl="8" class="mt-5 text-center" >
        <b>Certificate</b>
        <v-file-input
          label="Upload your certificate (Image/Pdf/Word)"
          outlined
          append-icon="mdi-file-pdf-outline"
          dense
          accept=".pdf, .doc, .docx, .jpg, .jpeg, .png"
          :rules="rules.file"
          show-size
          v-if="!dform.certificate || edit.certificate"
          @change="handleCertificate"
        >
        </v-file-input>
        <div v-else>
          <v-icon size="4rem">mdi-file-pdf-outline</v-icon>
          <v-btn
            color="primary"
            small
            shaped
            @click="edit.certificate = true"
            class="mr-1"
            >change file</v-btn
          >
          <v-btn
            :href="`/uploads/${application_year}/${dform.certificate}`"
            download
            small
            shaped
            >download file</v-btn
          >
        </div>
        <div v-if="edit.certificate">
          <v-btn color="primary" small shaped @click="edit.certificate = false"
            >cancel change file</v-btn
          >
        </div>
      </v-col>

      <v-col cols="12" lg="10" xl="8" class="text-center">
        <v-checkbox
        class="text-center"
          v-model="dform.awaiting_result"
          :true-value="1"
          :false-value="0"
          label="Still awaiting result"
        ></v-checkbox>
      </v-col>

      <v-col cols="12" lg="10" xl="8" class="text-center">
        <v-checkbox
        class="text-center"
          v-model="dform.declaration"
          :true-value="1"
          :false-value="0"
          label="I declare that the information provided in this application about and by me, is true, complete and correct under God."
        ></v-checkbox>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-btn class="px-2" small color="primary" @click="$emit('prev', { step: 5 })">
        Previous
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        v-if="canSubmit"
        color="primary"
        class="px-2" 
        :loading="submitLoading"
        @click="submitApplication"
        small
        :disabled ="!isValid"
      >
        SUBMIT Application
      </v-btn>
    </v-row>

    <v-dialog
      v-model="submitLoading"
      max-width="30rem"
      persistent
    >
      <v-card
        color="success"
        dark
      >
        <v-card-text class="text-h5 white--text">
          submitting your application, please wait...
          <v-progress-circular
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-circular>
        </v-card-text>
      </v-card>      
    </v-dialog>
    </v-container>
  </div>
</template>

<script>
import { apiClient } from "@/services";
import { editUserMixin } from "@/mixins";

export default {
  mixins: [editUserMixin],
  props: {
    application_no:{
      type: String,
      default: ""
    },
    application_year:Number,
    alert: Function,
    formId: [String, Number],
  },

  data() {
    return {
      submitLoading: false,
      tempFile: "",
      edit: { passport: false, certificate: false },
      attrs: {
        class: "mb-6",
        boilerplate: true,
        elevation: 2
      },

      dform: {
        passport: "",
        certificate: "",
        declaration: 0,
        awaiting_result:0,
        submitted: 0
      },
      

      rules: {
        img: [
          v => !v || v.type.includes("image/") || "Upload an image file",
          v => !v || v.size < 320000 || "Image size should be less than 300KB"
        ],
        file: [
          v =>
            !v ||
            v.name.includes(".pdf") ||
            v.type.includes("image/") ||
            v.name.includes(".doc") ||
            v.name.includes(".docx") ||
            "Upload Image/PDF/Word file",
          v => !v || v.size < 520000 || "File size should be less than 500KB"
        ]
      }
    };
  },

  computed: {
  isValid (){
    const reqf= ['passport','declaration']
    const validFields = reqf.every(v => !!this.dform[v])
    let validCert = false
    if(this.dform.awaiting_result){
      validCert = true
    }else if(!this.dform.awaiting_result && this.dform.certificate){
      validCert = true
    } 
    
    return validFields && validCert
    },
    canSubmit(){
      return !this.dform.submitted
    }
},


  methods: {
    handlePassport(file) {
      if (!file) {
        return;
      }
      const validImage = file.type.includes("image/") && file.size < 320000;
      if (!validImage) {
        this.alert("Invalid Passport Image!", "error");
        return;
      }
      this.processFile(file, "passport");
    },
    handleCertificate(file) {
      if (!file) {
        return;
      }
      const validFile =
        file.name.includes(".pdf") ||
        file.name.includes(".doc") ||
        file.name.includes(".docx") ||
        (file.type.includes("image/") && file.size < 520000);
      if (!validFile) {
        this.alert("Invalid Certificate file!", "error");
        return;
      }
      this.processFile(file, "certificate");
    },
    processFile(file, filename) {
      if(!this.formId){
        this.alert("Please start from the beginning and fill all the forms", "error");
        return;
      }
      let fd = new FormData();
      let data = {
        filename: this.dform[filename],
        application_no: this.application_no,
        applicant_id: this.formId,
        file_type: filename
      };
      fd.append("file", file);
      fd.append("data", JSON.stringify(data));
      let config = { header: { "Content-Type": "multipart/form-data" } };
      apiClient
        .post("/process_file.php", fd, config)
        .then(res => {
          if (res.data.filename) {
            this.dform[filename] = res.data.filename;
            if(this.edit[filename]){
              this.tempFile = this.dform[filename];
              this.dform[filename] = null;
              this.$nextTick(()=> {
                this.dform[filename] = this.tempFile
                this.edit[filename] = false;
              });            
            }
            
            this.alert("File uploaded successfully.");
          } else {
            this.alert(res.data, "error");
          }
        })
        .catch(err => this.alert(err.message, "error"));
    },

    submitApplication() {
      this.dform.id = this.formId;
      this.dform.type = "submitted";
      this.submitLoading = true;
      apiClient
        .put("/applicants.php", this.dform)
        .then(res => {
          if (res.data.ok) {
            this.alert("Application Submitted Successfully");
            this.submitLoading = false;
            this.$emit("application-submitted");
          } else {
            this.alert(res.data, "error");
            this.submitLoading = false;
          }
        })
        .catch(err => {
          this.alert(err.message, "error");
          this.submitLoading = false;
        });
    }
  }
};
</script>
