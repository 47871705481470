<template>
	<div>
<v-card elevation="3" class="pa-3">
            <h2 class="success white--text text-center mb-3">LOGIN</h2>

            <v-text-field
						v-model="dform.app_no"
              prepend-inner-icon="mdi-account-circle"
              label="Application Number"
              outlined
              dense
							:rules="[v => !!v || 'Item is required']"
            >
            </v-text-field>

            <v-text-field
              prepend-inner-icon="mdi-account-circle"
              label="Enter your Email or Phone Number"
              outlined
							v-model="dform.phonemail"
              dense
							:rules="[v => !!v || 'Item is required']"
            >
            </v-text-field>
            <v-text-field
						v-model="dform.npasswd"
              prepend-inner-icon="mdi-lock"
              label="New Password"
              outlined
              dense
							:rules="[v => !!v || 'Item is required']"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPassword ? 'text' : 'password'"
              @click:append="showPassword = !showPassword"
            >
            </v-text-field>
            <v-divider class="ma-2"></v-divider>
            <v-row justify="center" class="mt-3">
              <v-btn color="success" class="mb-2" :loading="loading" :disabled="!isValid" @click="submitPassword">
                Submit
              </v-btn>
            </v-row>
          </v-card>
						<v-snackbar v-model="snackbar" bottom :color="snackColor">
					<p>{{snackMsg}}</p>	
					<template v-slot:action="{ attrs }">
        <v-btn color="dark" v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
          Close
        </v-btn>
      </template>
						</v-snackbar>
	</div>
</template>

<script>
import {snackMixin} from "@/mixins";
import { apiClient } from "@/services";
	export default {
		name:"ForgetPassword",
		mixins: [snackMixin],

		data() {
			return {
				showPassword: false,

				dform:{
					app_no:"",
					phonemail:"",
					npasswd:""
				}
			}
		},

		computed: {
  isValid (){
    const reqf= ['app_no','phonemail','npasswd']
    return reqf.every(v => !!this.dform[v])
    }
},
		
		methods: {
			submitPassword() {
        this.loading = true;
				apiClient
        .post("/forgot_password.php", this.dform)
        .then(res => {
          if (res.data.id) {
						this.$router.push({
							name:"Application",
              params:{editUser: res.data}
						});
          } else{
						this.displayMsg(res.data, "error");
					}
        })
        .catch(err => this.displayMsg(err.message, "error"));
			}
		},
			}
</script>

